import React from "react";
import { images } from "common/images";
import Grid from "@mui/material/Grid";
import { InputField } from "components/input-field";
import { colors } from "common/theme";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export const InsuranceInputField = ({
  fields,
  fieldCount,
  handleInputChange,
  handleSubmit,
  handleDeleteButtonClick,
  insuranceRef,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={0.7}>
        {Array.from({ length: fieldCount }, (_, index) => {
          const suffix = index + 1;
          return (
            <React.Fragment key={index}>
              <div
                style={{
                  alignSelf: "flex-start",
                  fontSize: 16,
                  color: "black",
                  fontWeight: "700",
                  paddingTop: 5,
                  paddingBottom: 5,
                  marginTop: 10,
                  marginLeft: 3,
                  marginRight: 3,
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <span>{`Insurance`}</span>
                <button
                  className="delete-btn"
                  onClick={handleDeleteButtonClick}
                >
                  <div
                    style={{
                      width: 25,
                      borderRadius: 13,
                      height: 25,
                      backgroundColor: colors.darkRed,
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span>x</span>
                  </div>
                </button>
              </div>

              <Grid item xs={12} xl={12} lg={12} md={12}>
                <div className="mb-1">
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Type of insurance
                      </InputLabel>
                      <Select
                        sx={{
                          boxShadow: "none",
                          ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={fields[`type_${suffix}`]}
                        label="insurance type"
                        onChange={(e) =>
                          handleInputChange(`type_${suffix}`, e.target.value)
                        }
                        placeholder={`Insurance Type ${suffix}`}
                      >
                        <MenuItem value={"Health"}>Health</MenuItem>
                        <MenuItem value={"Travel"}>Travel</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12} xl={12} lg={12} md={12}>
                <div className="mb-1">
                  <InputField
                    name={`company_${suffix}`}
                    id={`company_${suffix}`}
                    placeholder={`Insurance Company`}
                    value={fields[`company_${suffix}`]}
                    onChange={(e) =>
                      handleInputChange(`company_${suffix}`, e.target.value)
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12} xl={12} lg={12} md={12}>
                <div className="mb-1">
                  <InputField
                    name={`policy_${suffix}`}
                    id={`policy_${suffix}`}
                    placeholder={`Policy Number`}
                    value={fields[`policy_${suffix}`]}
                    onChange={(e) =>
                      handleInputChange(`policy_${suffix}`, e.target.value)
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12} xl={12} lg={12} md={12}>
                <div className="mb-1" ref={insuranceRef}>
                  <InputField
                    phone={true}
                    customStyle={"pass-input-custom-style"}
                    name={`phone_${suffix}`}
                    id={`phone_${suffix}`}
                    placeholder={`Emergency Number`}
                    value={fields[`phone_${suffix}`]}
                    onChange={(e) =>
                      handleInputChange(`phone_${suffix}`, e.target.value)
                    }
                  />
                </div>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </form>
  );
};
