import React, { useContext, useEffect, useRef, useState } from "react";
import "./styles.scss";
import StepWizard from "react-step-wizard";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  EmergencyInputField,
  GenericButton,
  GenericModal,
  InputField,
  InsuranceInputField,
  ActionButtons,
  PhoneInputField,
} from "components";
import Grid from "@mui/material/Grid";
import {
  signUpSchema,
  signUpStep1Schema,
  signUpStep2Schema,
  signUpStep3Schema,
  signUpStep4Schema,
} from "common/schemas";
import { Formik } from "formik";
import { ENUMS, GenderList, constants, countriesList, emergencyRelationshipList } from "common";
import { images } from "common/images";
import PasswordStrengthBar from "react-password-strength-bar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { performSignUpThroughFirebase } from "services";
import Autocomplete from "react-google-autocomplete";
import { RibbonContainer, Ribbon } from "react-ribbons";
import Stripe from "stripe";
import {
  checkIfEmailExists,
  getUserDetails,
  performLoginThroughFirebase,
  saveUserData,
} from "services/firebaseServices";
import {
  setTempUserDetail,
  getTempUserDetail,
  setToken,
  setProfile,
  setSecondaryProfile,
} from "utilities/localStorage";
import { routePaths } from "routes";
import { useSearchParams } from "react-router-dom";
import { formatDate } from "pages/home";
import { ApplicationContext } from "shared";
import { nationality } from "common/nationality";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { OrganDonarOptions, selectedStripeKey } from "common/constants";
import { MedicalCard } from "../../components/medical-card";
import { Navigation } from "components/navigation";
import { isEighteenOrOlder } from "common/isEighteenOrOlder";
import { civilStatus } from "common/civilstatus";

const {
  formHeadings: { step1, step2, step3, step4, step5, step6 },
  generateText,
  getOurApp,
  pleaseFilloutPersonalInfo,
  registerNewWMCAcc,
  alreadyHaveAndAcc,
  placeholders,
  fieldTypeEnums,
} = constants;

export const converDateFormate = (dateString) => {
  const originalDate = new Date(dateString);
  const formattedDate = originalDate.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  return formattedDate;
};
const SignUp = () => {
  let navigate = useNavigate();
  const { company } = useParams();
  const isCompanyLogin = !!company;
  const [btnLoader, setBtnLoader] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderNowBtnLoader, setOrderNowBtnLoader] = useState(false);
  const [password, setPassword] = useState("");
  const { wmcLogo, mobileLogo, appStoreLogo, playStoreLogo } = images;
  const [activeStep, setActiveStep] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [apiResponse, setApiResponse] = useState({
    type: ENUMS.MODAL_TYPES.ERROR,
    title: "",
    body: "",
  });
  const [userDetails, setUserDetails] = useState({
    avatar: "",
    firstName: "",
    lastName: "",
    email: "",
    birthDate: "",
    gender: "",
    cprNumber: "",
    nationality: "",
    phone: "",
    ssn: "",
    donarNumber: "",
    address: "",
    insuranceType: "Choose",
    insuranceCompany: "",
    policyNumber: "",
    emergencyNumber: "",
    emergencyName: "",
    emergencyPhone: "",
    emergencyRelation: "",
    password: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    organDonation: "",
    cardOrderStatus: "",
    cardOrderDate: "",
    isActiveSubscription: true,
    isForcedRefreshToken: false,
    memberSince: formatDate(new Date()),
    cardReOrderStatus: "",
    cardORerderDate: "",
    homephone: "",
    confirmPassword: "",
    company: isCompanyLogin ? company : 'Self',
    relationShip:"",
  });
  const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0;
  };
  const transformUserData = (flatUserData) => {
    let insuranceExtra = extractItemBeforeUnderscore(insuranceFields) ?? [];
    let emergencyExtra = extractItemBeforeUnderscore(emrgencyExtFields) ?? [];
    const {
      insuranceType,
      insuranceCompany,
      policyNumber,
      emergencyNumber,
      emergencyName,
      emergencyPhone,
      emergencyRelation,
      address,
      city,
      state,
      zipCode,
      country,
      ...rest
    } = flatUserData;
    let tempInsurance = {
      type: insuranceType,
      company: insuranceCompany,
      policy: policyNumber,
      phone: emergencyNumber,
    };
    let tempEmergency = {
      name: emergencyName,
      phone: emergencyPhone,
      relationship: emergencyRelation,
    };
    const updatedInsurance = [...insuranceExtra, tempInsurance];
    const updatedEmergency = [...emergencyExtra, tempEmergency];
    const userDetails = {
      ...rest,
      insurance: updatedInsurance,
      emergencyContact: updatedEmergency,
      postalAddress: [
        {
          city: city,
          state: state,
          info: address,
          zipCode: zipCode,
          country: country,
        },
      ],
    };
    return userDetails;
  };

  useEffect(() => {
    if (searchParams?.get("session_id")) {
      const sessionId = searchParams?.get("session_id");
      saveDataToFirebase(sessionId);
    }
  }, [searchParams]);

  function extractItemBeforeUnderscore(data) {
    return data.map((item) => {
      const result = {};

      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          const [itemName, index] = key.split("_");
          result[itemName] = item[key];
        }
      }

      return result;
    });
  }

  const handleStepChange = (e) => {
    setActiveStep(e.activeStep - 1);
  };

  const generatePassword = () => {
    let charset = "";
    let newPassword = "";
    charset += "!@#$%^&*()";
    charset += "0123456789";
    charset += "abcdefghijklmnopqrstuvwxyz";
    charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    for (let i = 0; i < 12; i++) {
      newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
    }

    setPassword(newPassword);
  };

  const convertArrayToString = (data) => {
    const joined = data.join();
    let newAddress = joined.replace(/,/g, " ");
    return newAddress;
  };

  const AddressSeprateHandler = (data, setFieldValue, values) => {
    let address = [];
    let city = [];
    let state = [];
    let postal = [];
    let country = [];
    data.map((dataItem) => {
      dataItem.types.map((type) => {
        if (type === fieldTypeEnums.streetNumber) {
          address.push(dataItem.long_name);
        } else if (type === fieldTypeEnums.route) {
          address.push(dataItem.long_name);
        } else if (type === fieldTypeEnums.naibhour) {
          address.push(dataItem.long_name);
        } else if (type === fieldTypeEnums.locality) {
          city.push(dataItem.long_name);
        } else if (type === fieldTypeEnums.admin) {
          state.push(dataItem.long_name);
        } else if (type === fieldTypeEnums.country) {
          country.push(dataItem.long_name);
        } else if (type === fieldTypeEnums.code) {
          postal.push(dataItem.long_name);
        }
      });
    });
    setFieldValue(userDetails.address, values);
    setFieldValue(userDetails.city, convertArrayToString(city));
    setFieldValue(userDetails.state, convertArrayToString(state));
    setFieldValue(userDetails.zipCode, convertArrayToString(postal));
    setFieldValue(userDetails.country, convertArrayToString(country));
    setUserDetails((data) => ({
      ...data,
      address: values,
      city: convertArrayToString(city),
      state: convertArrayToString(state),
      zipCode: convertArrayToString(postal),
      country: convertArrayToString(country),
    }));
  };

  function Step1(props) {
    return (
      <Formik
        initialValues={{
          email: userDetails.email,
          password: password || userDetails.password,
          confirmPassword: password || "",
        }}
        validateOnMount={true}
        onSubmit={(values) => {
          checkEmail(values, props);
        }}
        validationSchema={signUpSchema}
        enableReinitialize
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          errors,
          values,
        }) => (
          <div className="sign-up-step-cont">
            <div>
              <div className="sign-up-form-heading">
                <span>{"Sign Up"}</span>
              </div>
              <Grid container spacing={0.7}>
                <Grid item xs={12} xl={12} lg={12} md={12}>
                  <InputField
                    icon={<span class="icon-email"></span>}
                    name={"email"}
                    id={"email"}
                    placeholder={"Email"}
                    error={touched.email && errors.email}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.email}
                  />
                </Grid>
                <Grid item xs={12} xl={12} lg={12} md={12}>
                  <InputField
                    customStyle={"pass-input-custom-style"}
                    id={"password"}
                    name={"password"}
                    placeholder={"Password"}
                    error={touched.password && errors.password}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    value={values.password}
                    isPassword
                    isSearch
                  />
                  {values.password != "" && (
                    <PasswordStrengthBar
                      password={values.password || password}
                    />
                  )}
                </Grid>
                <Grid item xs={12} xl={12} lg={12} md={12}>
                  <InputField
                    customStyle={"pass-input-custom-style"}
                    id={"confirmPassword"}
                    name={"confirmPassword"}
                    placeholder={"Confirm Password"}
                    error={touched.confirmPassword && errors.confirmPassword}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    value={values.confirmPassword}
                    isPassword
                    isSearch
                  />
                  {values.confirmPassword != "" && (
                    <PasswordStrengthBar
                      password={values.confirmPassword || password}
                    />
                  )}
                </Grid>
                {/* <div className="password-generator-cont">
                  <div className="generate-text-btn-cont">
                    <div>
                      <button
                        className="buttonStyle"
                        onClick={() => {
                          setUserDetails((data) => ({
                            ...data,
                            email: values.email,
                          }));
                          generatePassword();
                        }}
                      >
                        <span className="generate-text">{generateText}</span>
                      </button>
                    </div>
                  </div>
                </div> */}
              </Grid>
            </div>
            <ActionButtons
              setUserDetails={setUserDetails}
              userDetails={userDetails}
              handleSubmit={handleSubmit}
              activeStep={activeStep}
              {...props}
            />
          </div>
        )}
      </Formik>
    );
  }

  function Step2(props) {
    return (
      <Formik
        initialValues={{
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          dob: userDetails.birthDate || null,
          phone: userDetails.phone,
          nationality: userDetails.nationality,
          gender: userDetails.gender,
          socialNum: userDetails.ssn,
          organDonar: userDetails.organDonation,
          homephone: userDetails.homephone,
          relationShip:userDetails.relationShip
        }}
        validateOnMount={true}
        onSubmit={(values) => {
          let date = converDateFormate(values.dob);
          console.log("date", date)
          setUserDetails((data) => ({
            ...data,
            firstName: values.firstName,
            lastName: values.lastName,
            birthDate: date,
            phone: values.phone,
            nationality: values.nationality,
            gender: values.gender,
            ssn: values.socialNum,
            organDonation: values.organDonar,
            homephone: values.homephone,
            relationShip:values.relationShip
          }));
          props.nextStep();
        }}
        validationSchema={signUpStep1Schema}
        enableReinitialize={true}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          errors,
          values,
          setFieldValue,
        }) => (
          <div style={{ padding: 10 }}>
            <div>
              <div className="sign-up-form-heading">
                <span>{"Personal Information"}</span>
              </div>
              <Grid container spacing={0.7}>
                <Grid item xs={12} xl={6} lg={6} md={6}>
                  <div className="mb">
                    <InputField
                      name={"firstName"}
                      id={"firstName"}
                      error={touched.firstName && errors.firstName}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      placeholder={"First Name"}
                      value={values.firstName}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} xl={6} lg={6} md={6}>
                  <InputField
                    name={"lastName"}
                    id={"lastName"}
                    error={touched.lastName && errors.lastName}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    placeholder={"Last Name"}
                    value={values.lastName}
                  />
                </Grid>
                <Grid item xs={12} xl={6} lg={6} md={6}>
                  <div className="full-width-datepicker">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker", "DatePicker"]}>
                        <DatePicker
                          maxDate={dayjs(new Date())}
                          value={dayjs(values.dob) || ""}
                          onChange={(e) => {
                            let d = new Date(e.$d).getTime()
                            console.log("dobchange", isEighteenOrOlder(e.$d))

                            handleChange("dob");
                            setFieldValue("dob", e.$d);
                          }}
                          format="DD/MM/YYYY"
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    <div className="error-input-container">
                      {errors.dob && touched.dob ? (
                        <p className="form-error">{errors.dob}</p>
                      ) : null}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} xl={6} lg={6} md={6}>
                  <div className="mb">
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Gender
                        </InputLabel>
                        <Select
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={values.gender}
                          label="gender"
                          onChange={handleChange("gender")}
                        >
                          {GenderList.map(({ value, label }) => {
                            return <MenuItem value={value}>{label}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                    <div className="error-input-container">
                      {errors.gender && touched.gender ? (
                        <p className="form-error">{errors.gender}</p>
                      ) : null}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} xl={6} lg={6} md={6}>
                  <div className="mb-1">
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Nationality
                        </InputLabel>
                        <Select
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={values.nationality}
                          label="Select a nationality"
                          onChange={handleChange("nationality")}
                        >
                          {nationality.map((nationality, index) => (
                            <MenuItem key={index} value={nationality}>
                              {nationality}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <div className="error-input-container">
                      {errors.nationality && touched.nationality ? (
                        <p className="form-error">{errors.nationality}</p>
                      ) : null}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} xl={6} lg={6} md={6}>
                  <div className="mb-1">
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Civil Status
                        </InputLabel>
                        <Select
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={values.relationShip}
                          label="Civil Status"
                          onChange={handleChange("relationShip")}
                        >
                          {civilStatus.map((relationShip, index) => (
                            <MenuItem key={index} value={relationShip}>
                              {relationShip}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  
                  </div>
                </Grid>
                <Grid item xs={12} xl={6} lg={6} md={6}>
                  <div className="mb">
                    <InputField
                      name={"socialNum"}
                      id={"socialNum"}
                      error={touched.socialNum && errors.socialNum}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      placeholder={"National ID number"}
                      value={values.socialNum}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} xl={6} lg={6} md={6}>
                  <PhoneInputField
                    name={"phone"}
                    id={"phone"}
                    country={"us"}
                    value={values.phone}
                    handleChange={handleChange("phone")}
                    handleBlur={handleBlur("phone")}
                    placeholder={"Phone Number"}
                    error={touched.phone && errors.phone}
                  />
                </Grid>
                <Grid item xs={12} xl={6} lg={6} md={6}>
                  <PhoneInputField
                    name={"homephone"}
                    id={"homephone"}
                    value={values.homephone}
                    handleChange={handleChange("homephone")}
                    handleBlur={handleBlur("homephone")}
                    placeholder={"Home Phone"}
                    error={touched.homephone && errors.homephone}
                  />
                </Grid>
                <label style={{ paddingLeft: 10, paddingTop: 10 }}>
                  Would you like to be an organ donor? (optional)
                </label>
                <Grid item xs={12} xl={12} lg={12} md={12}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Organ Donor
                      </InputLabel>
                      <Select
                        sx={{
                          boxShadow: "none",
                          ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.organDonar}
                        label="Organ Donor"
                        onChange={handleChange("organDonar")}
                        placeholder="Organ Donor"
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            </div>
            <ActionButtons
              setUserDetails={setUserDetails}
              userDetails={userDetails}
              handleSubmit={handleSubmit}
              activeStep={activeStep}
              {...props}
            />
          </div>
        )}
      </Formik>
    );
  }

  function Step3(props) {
    return (
      <Formik
        initialValues={{
          address: userDetails.address,
          city: userDetails.city,
          state: userDetails.state,
          zipCode: userDetails.zipCode,
          country: userDetails.country,
        }}
        validateOnMount={true}
        onSubmit={(values) => {
          setUserDetails((data) => ({
            ...data,
            address: values.address,
            city: values.city,
            state: values.state,
            zipCode: values.zipCode,
            country: values.country,
          }));
          props.nextStep();
        }}
        validationSchema={signUpStep2Schema}
        enableReinitialize
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          errors,
          values,
          setFieldValue,
        }) => (
          <div style={{ padding: 10 }}>
            <div>
              <div className="sign-up-form-heading">
                <span>{"Address"}</span>
              </div>
              <Grid container spacing={0.7}>
                <Grid item xs={12} xl={12} lg={12} md={12}>
                  <div className="mb">
                    <Autocomplete
                      className="input-field-generic auto-complete-address"
                      apiKey={constants.googleAPIKey}
                      defaultValue={values.address}
                      onChange={handleChange("address")}
                      onBlur={handleBlur}
                      onPlaceSelected={(data) => {
                        AddressSeprateHandler(
                          data.address_components,
                          setFieldValue,
                          data.formatted_address
                        );
                      }}
                    />
                    <div className="error-input-container">
                      {errors.address && touched.address ? (
                        <p className="form-error">{errors.address}</p>
                      ) : null}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} xl={6} lg={6} md={6}>
                  <div className="mb-1">
                    <InputField
                      name={"city"}
                      id={"city"}
                      error={touched.city && errors.city}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.city}
                      placeholder={placeholders.city}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} xl={6} lg={6} md={6}>
                  <div className="mb-1">
                    <InputField
                      name={"state"}
                      id={"state"}
                      error={touched.state && errors.state}
                      handleChange={handleChange("state")}
                      handleBlur={handleBlur}
                      value={values.state}
                      placeholder={placeholders.region}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} xl={6} lg={6} md={6}>
                  <div className="mb-1">
                    <InputField
                      name={"zipCode"}
                      id={"zipCode"}
                      error={touched.zipCode && errors.zipCode}
                      handleChange={handleChange("zipCode")}
                      handleBlur={handleBlur}
                      value={values.zipCode}
                      placeholder={placeholders.postalCode}
                      autoComplete="new-password"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} xl={6} lg={6} md={6}>
                  <div className="mb-1">
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Country
                        </InputLabel>
                        <Select
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={(countriesList.some(item => item.value == values.country || '')) ? values.country : ""}
                          label="Select Country"
                          onChange={handleChange("country")}
                        >
                          {countriesList.map(({ value, label }, index) => (
                            <MenuItem key={index} value={value}>
                              {label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <div className="error-input-container">
                      {errors.country && touched.country ? (
                        <p className="form-error">{errors.country}</p>
                      ) : null}
                    </div>
                  </div>
                </Grid>


                <Grid item xs={12} xl={6} lg={6} md={6}>
                  {/* <div className="mb-1">
                    <InputField
                      name={"country"}
                      id={"country"}
                      error={touched.country && errors.country}
                      handleChange={handleChange("country")}
                      handleBlur={handleBlur}
                      value={values.country}
                      placeholder={placeholders.country}
                    />
                  </div> */}

                  {/* <div className="mb-1">
                    <Select
                      sx={{
                        boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.country}
                      label="Country"
                      onChange={handleChange("country")}
                      placeholder="Country"
                    >
                      {countriesList.map(({label, value}) => {
                        return <MenuItem value={value}>{label}</MenuItem>
                      })}
                    </Select>
                  </div> */}


                </Grid>
              </Grid>
            </div>
            <ActionButtons
              setUserDetails={setUserDetails}
              userDetails={userDetails}
              handleSubmit={handleSubmit}
              activeStep={activeStep}
              {...props}
            />
          </div>
        )}
      </Formik>
    );
  }
  const insuranceExtraFields = [];
  const [insuranceFields, setInsuranceFields] = useState([]);
  function Step4(props) {
    const { fieldCount, setFieldCount, fields, setFields } =
      useContext(ApplicationContext);
    const handleInputChange = (fieldName, value) => {
      setFields({ ...fields, [fieldName]: value });
    };
    const handlePlusButtonClick = () => {
      let newFields = {};
      newFields = { ...fields, ...newFields };
      for (let i = 2; i <= fieldCount + 1; i++) {
        newFields[`type_${i}`] = "";
        newFields[`company_${i}`] = "";
        newFields[`policy_${i}`] = "";
        newFields[`phone_${i}`] = "";
      }
      setFields(newFields);
      setFieldCount(fieldCount + 1);
    };
    const handleDeleteButtonClick = () => {
      const newFields = {};
      for (let i = 0; i < 4; i++) {
        const suffix = fieldCount - i;
        delete newFields[`type_${suffix}`];
        delete newFields[`company_${suffix}`];
        delete newFields[`policy_${suffix}`];
        delete newFields[`phone_${suffix}`];
      }
      setFields(newFields);
      setFieldCount(fieldCount - 1);
    };
    const moreFieldsHandleSubmit = (event) => {
      event?.preventDefault();
      if (fieldCount == 0) {
        return;
      } else {
        if (isObjectEmpty(fields) == false) {
          insuranceExtraFields.push(fields);
        }
      }
      setInsuranceFields(insuranceExtraFields);
    };
    const insuranceRef = useRef();

    return (
      <Formik
        initialValues={{
          insuranceType: userDetails.insuranceType,
          insuranceCompany: userDetails.insuranceCompany,
          policyNumber: userDetails.policyNumber,
          emergencyNumber: userDetails.emergencyNumber,
        }}
        validateOnMount={true}
        onSubmit={(values, { setFieldError }) => {
          const {
            insuranceType,
            insuranceCompany,
            policyNumber,
            emergencyNumber,
          } = values;

          const isAnyFieldFilled = insuranceType || insuranceCompany || policyNumber || emergencyNumber;
          const areAllFieldsFilled = insuranceType && insuranceCompany && policyNumber && emergencyNumber;

          if (isAnyFieldFilled && !areAllFieldsFilled && insuranceType !== "Choose") {
            if (!insuranceType) setFieldError('insuranceType', 'InsuranceType Required');
            if (!insuranceCompany) setFieldError('insuranceCompany', 'InsuranceCompany Required');
            if (!policyNumber) setFieldError('policyNumber', 'PolicyNumber Required');
            if (!emergencyNumber) setFieldError('emergencyNumber', 'EmergencyNumber Required');
            return;
          }
          moreFieldsHandleSubmit();
          setUserDetails((data) => ({
            ...data,
            insuranceType: values.insuranceType === "Choose" ? "" : values.insuranceType,
            insuranceCompany: values.insuranceCompany,
            policyNumber: values.policyNumber,
            emergencyNumber: values.emergencyNumber,
          }));
          props.nextStep();
        }}
        validationSchema={signUpStep3Schema}
        enableReinitialize
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          errors,
          values,
          setFieldValue,
        }) => (
          <>
            <div className="scroll">
              <div>
                <div className="insurance-heading">
                  <span>{"Insurance"}</span>
                </div>
                <Grid container spacing={0.7}>
                  <Grid item xs={12} xl={12} lg={12} md={12}>
                    <div className="mb-1">
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Type of insurance
                          </InputLabel>
                          <Select
                            sx={{
                              boxShadow: "none",
                              ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values.insuranceType}
                            label="insurance type"
                            onChange={handleChange("insuranceType")}
                            placeholder="Type of insurance"
                          >
                            <MenuItem value={"Choose"}>Choose</MenuItem>
                            <MenuItem value={"Health"}>Health</MenuItem>
                            <MenuItem value={"Travel"}>Travel</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      <div className="error-input-container">
                        {errors.insuranceType && touched.insuranceType ? (
                          <p className="form-error">{errors.insuranceType}</p>
                        ) : null}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} xl={12} lg={12} md={12}>
                    <div className="mb-1">
                      <InputField
                        name={"insuranceCompany"}
                        id={"insuranceCompany"}
                        error={
                          touched.insuranceCompany && errors.insuranceCompany
                        }
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        fullWidth
                        placeholder={"Insurance Company"}
                        value={values.insuranceCompany}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} xl={12} lg={12} md={12}>
                    <div className="mb-1">
                      <InputField
                        name={"policyNumber"}
                        id={"policyNumber"}
                        error={touched.policyNumber && errors.policyNumber}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        fullWidth
                        placeholder={"Policy Number"}
                        value={values.policyNumber}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} xl={12} lg={12} md={12}>
                    <div className="mb-1">
                      <PhoneInputField
                        name={"emergencyNumber"}
                        id={"emergencyNumber"}
                        error={
                          touched.emergencyNumber && errors.emergencyNumber
                        }
                        placeholder={"Emergency phone"}
                        value={values.emergencyNumber}
                        handleBlur={handleBlur("emergencyNumber")}
                        handleChange={handleChange("emergencyNumber")}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <InsuranceInputField
                fields={fields}
                fieldCount={fieldCount}
                handleInputChange={handleInputChange}
                handleSubmit={moreFieldsHandleSubmit}
                handleDeleteButtonClick={handleDeleteButtonClick}
                insuranceRef={insuranceRef}
              />
            </div>
            <div
              style={{
                alignItems: "center",
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              <div style={{ width: "30%" }}>
                <GenericButton
                  disable={fieldCount == 2}
                  buttonText={"+ Add Insurance"}
                  customStyle={"add-more-fields-btn"}
                  onPress={() => {
                    handlePlusButtonClick();
                    insuranceRef.current?.scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                />
              </div>
            </div>
            <ActionButtons
              setUserDetails={setUserDetails}
              userDetails={userDetails}
              handleSubmit={handleSubmit}
              // moreFieldsHandleSubmit={moreFieldsHandleSubmit}
              activeStep={activeStep}
              {...props}
            />
          </>
        )}
      </Formik>
    );
  }
  const emergencyExtraFields = [];
  const [emrgencyExtFields, setExtEmergencyFields] = useState([]);
  function Step5(props) {
    const {
      emergencyfields,
      setEmergencyFields,
      emergencyFieldCount,
      setEmergencyFieldCount,
    } = useContext(ApplicationContext);
    const emergencyRef = useRef();
    const handleInputChange = (fieldName, value) => {
      setEmergencyFields({ ...emergencyfields, [fieldName]: value });
    };
    const handlePlusButtonClick = (event) => {
      event?.preventDefault();
      let newFields = {};
      newFields = { ...emergencyfields, ...newFields };
      for (let i = 2; i <= emergencyFieldCount + 1; i++) {
        newFields[`emergencyName${i}`] = "";
        newFields[`emergencyPhone${i}`] = "";
        newFields[`emergencyRelation${i}`] = "";
      }
      setEmergencyFields(newFields);
      setEmergencyFieldCount(emergencyFieldCount + 1);
    };
    const handleDeleteButtonClick = () => {
      const newFields = { ...emergencyfields };
      for (let i = 0; i < 4; i++) {
        const suffix = emergencyFieldCount - i;
        delete newFields[`emergencyName${suffix}`];
        delete newFields[`emergencyPhone${suffix}`];
        delete newFields[`emergencyRelation${suffix}`];
      }
      setEmergencyFields(newFields);
      setEmergencyFieldCount(emergencyFieldCount - 1);
    };
    const moreFieldsHandleSubmit = (event) => {
      if (emergencyFieldCount == 0) {
        return;
      } else {
        if (isObjectEmpty(emergencyfields) == false) {
          emergencyExtraFields.push(emergencyfields);
        }
      }
      setExtEmergencyFields(emergencyExtraFields);
    };

    return (
      <Formik
        initialValues={{
          emergencyName: userDetails.emergencyName,
          emergencyPhone: userDetails.emergencyPhone,
          emergencyRelation: userDetails.emergencyRelation,
        }}
        validateOnMount={true}
        onSubmit={(values) => {
          moreFieldsHandleSubmit();
          setUserDetails((data) => ({
            ...data,
            emergencyName: values.emergencyName,
            emergencyPhone: values.emergencyPhone,
            emergencyRelation: values.emergencyRelation,
          }));
          if (!isCompanyLogin) {
            props.nextStep();
          }
          else {
            setOrderNowBtnLoader(true);
            performSignUp(userDetails);
          }
        }}
        validationSchema={signUpStep4Schema}
        enableReinitialize
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          errors,
          values,
          setFieldValue,
        }) => (
          <>
            <div className="scroll">
              <div>
                <div className="insurance-heading">
                  <span>{"Emergency"}</span>
                </div>

                <Grid container spacing={0.7}>
                  <Grid item xs={12} xl={12} lg={12} md={12}>
                    <div className="mb-1">
                      <InputField
                        name={"emergencyName"}
                        id={"emergencyName"}
                        error={touched.emergencyName && errors.emergencyName}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        fullWidth
                        placeholder={"Name"}
                        value={values.emergencyName}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} xl={12} lg={12} md={12}>
                    <div className="mb-1">
                      <PhoneInputField
                        name={"emergencyPhone"}
                        id={"emergencyPhone"}
                        error={touched.emergencyPhone && errors.emergencyPhone}
                        placeholder={"Phone"}
                        value={values.emergencyPhone}
                        handleBlur={handleBlur("emergencyPhone")}
                        handleChange={handleChange("emergencyPhone")}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} xl={12} lg={12} md={12}>
                    <div className="mb-1">
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Relationship
                          </InputLabel>
                          <Select
                            sx={{
                              boxShadow: "none",
                              ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values.emergencyRelation}
                            label="Relationship"
                            placeholder="Relationship"
                            onChange={handleChange("emergencyRelation")}
                          >
                            {
                              emergencyRelationshipList.map(({ value, label }) => {
                                return (
                                  <MenuItem value={value}>{label}</MenuItem>
                                )
                              })
                            }
                          </Select>
                        </FormControl>
                      </Box>
                      <div className="error-input-container">
                        {errors.emergencyRelation &&
                          touched.emergencyRelation ? (
                          <p className="form-error">
                            {errors.emergencyRelation}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <EmergencyInputField
                fields={emergencyfields}
                fieldCount={emergencyFieldCount}
                handleInputChange={handleInputChange}
                handleSubmit={moreFieldsHandleSubmit}
                handleDeleteButtonClick={handleDeleteButtonClick}
                emergencyRef={emergencyRef}
              />
              <div
                style={{
                  alignItems: "center",
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              >
                <div style={{ width: "30%" }}>
                  <GenericButton
                    disable={emergencyFieldCount == 2}
                    buttonText={"+ Add Contact"}
                    customStyle={"add-more-fields-btn"}
                    onPress={() => {
                      handlePlusButtonClick();
                      emergencyRef.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <ActionButtons
              setUserDetails={setUserDetails}
              userDetails={userDetails}
              handleSubmit={handleSubmit}
              activeStep={activeStep}
              loading={orderNowBtnLoader}
              {...props}
            />
          </>
        )}
      </Formik>
    );
  }

  function Step6(props) {
    return (
      <>
        <div className="checkout-cont">
          <div className="order-now-cont">
            <RibbonContainer>
              <Ribbon
                side="left"
                type="corner"
                size="large"
                backgroundColor="#cc0000"
                color="#ccffff"
                fontFamily="sans"
                withStripes
              >
                OFFER
              </Ribbon>
              <div className="price-table-content">
                <div className="plan-heading">
                  <h4>Private</h4>
                  <div className="plan-amount-cont">
                    <span className="currency-sign">€</span>
                    <div className="price-cont">
                      <h3>9.95</h3>
                      <p>/year</p>
                    </div>
                  </div>
                </div>
                <ul class="sample">
                  <li>Instantly locate Hospitals & Pharmacies</li>
                  <li>
                    <span>
                      Translate Allergies, Medicine, Diagnoses & Vaccines into
                      135 languages
                    </span>
                  </li>
                  <li>Store health documents & prescriptions</li>
                  <li>Add Insurance Information</li>
                  <li>Share your health information</li>
                  <li>WMC Emergency Card Included</li>
                </ul>
                <GenericButton
                  loading={orderNowBtnLoader}
                  onPress={() => {
                    setOrderNowBtnLoader(true);
                    performSignUp(userDetails);
                  }}
                  buttonText={"Order Now"}
                  customStyle={"order-now-button"}
                />
              </div>
            </RibbonContainer>
          </div>
          <div className="order-now-cont">
            <div className="corporate-table">
              <div className="plan-heading">
                <h3>Corporate</h3>
                <div className="plan-amount-cont">
                  <span className="currency-sign">€</span>
                  <div className="price-cont">
                    <h3>11.95</h3>
                    <p>/year</p>
                  </div>
                </div>
                <span>
                  Contact us to receive a customised offer including customer
                  support and co-branding.
                </span>
              </div>
              <GenericButton
                buttonText={"Contact"}
                customStyle={"contact-now-button"}
                onPress={() => {
                  window.open(
                    `mailto:Contact@wmc-card.no?subject=Request For World Medical Corporate Card &body=First Name: ${userDetails.firstName} Last Name: ${userDetails.lastName}
                      Email: ${userDetails.email}
                      Date Of Birth: ${userDetails.birthDate}
                      Gender: ${userDetails.gender}
                      Nationality: ${userDetails.nationality}
                      Social Number: ${userDetails.ssn}
                      Address: ${userDetails.address}
                      City: ${userDetails.city}
                      State: ${userDetails.state}
                      Zip Code: ${userDetails.zipCode}
                      Insurance Type: ${userDetails.insuranceType}
                      Insurance Company: ${userDetails.insuranceCompany}
                      Policy Number: ${userDetails.policyNumber}
                      Emergency Name: ${userDetails.emergencyName}
                      Emergency Phone: ${userDetails.emergencyPhone}
                      Emergency Relation: ${userDetails.emergencyRelation}`
                  );
                }}
              />
            </div>
          </div>
        </div>
        <ActionButtons
          setUserDetails={setUserDetails}
          userDetails={userDetails}
          activeStep={activeStep}
          handleSubmit={() => console.log("")}
          {...props}
        />
      </>
    );
  }
  const steps = isCompanyLogin ? [step1, step2, step3, step4, step5] : [step1, step2, step3, step4, step5, step6];

  const CreateCardStripe = async (uid) => {
    let selectedCurr = "EUR";
    // let amount = 1178.00;
    let amount = 995.00;
    try {
      const result = await fetch('https://open.er-api.com/v6/latest/EUR');
      const currencies = await result.json();
      const myCurrency = await fetch('https://ipapi.co/currency');
      const code = await myCurrency.text();
      selectedCurr = code;
      // amount = Math.ceil(currencies.rates[code]*1178);
      amount = Math.ceil(currencies.rates[code] * 995);
    } catch (err) {
      selectedCurr = "EUR";
      // amount = 1178.00;
      amount = 995.00;
    }
    const stripe = new Stripe(selectedStripeKey);
    // debugger
    try {
      const params = {
        mode: "payment",
        payment_method_types: ["card"],
        line_items: [
          {
            price_data: {
              currency: selectedCurr,
              product_data: {
                name: "Personal Safety",
              },
              unit_amount_decimal: amount + '',
              // recurring: {
              //   // Set up recurring payment
              //   interval: "year",
              //   interval_count: 1,
              // },
            },
            adjustable_quantity: {
              enabled: false,
            },
            quantity: 1,
          },
        ],
        allow_promotion_codes: true,
        success_url: `${constants.siteBaseURL}/sign-up?session_id=${uid}`,
        cancel_url: `${constants.siteBaseURL}/setup/subscription`,
      };
      const session = await stripe.checkout.sessions.create(params);
      if (session?.url) {
        window.location.assign(session?.url);
      }
    } catch (err) {
      console.log("Error creating checkout session");
    }
  };
  const performSignUp = async (payload) => {
    // debugger;
    try {
      let response = await performSignUpThroughFirebase(payload);
      if (response) {
        const {
          user: { accessToken, uid, reloadUserInfo },
        } = response;
        const item = transformUserData(userDetails);
        setTempUserDetail({
          uid,
          item,
        });
        if (!isCompanyLogin) {
          CreateCardStripe(uid);
        }
        else {
          const getData = await getTempUserDetail();
          const url = `${constants.siteBaseURL}/${company}/sign-up?session_id=${uid}`;
          window.location.assign(url);
        }
      }
      setOrderNowBtnLoader(false);
    } catch (error) {
      setShowModal(true);
      setApiResponse({
        type: ENUMS.MODAL_TYPES.ERROR,
        title: "Error",
        body: "Email already exists.",
      });
      console.log(error);
    }
  };
  const saveDataToFirebase = async (id) => {
    const getData = await getTempUserDetail();
    if (getData) {
      let response = await saveUserData(id, getData?.item);
      if (response == undefined) {
        setShowModal(true);
        localStorage.setItem("welcome", "true")
        setApiResponse({
          type: ENUMS.MODAL_TYPES.SUCCESS,
          title: "Success",
          body: "Your Account Has been Created",
        });
      }
    }
  };
  const checkEmail = async (payload, props) => {
    const response = await checkIfEmailExists(payload?.email);
    if (response) {
      setUserDetails((data) => ({
        ...data,
        email: payload.email,
        password: payload.password,
        confirmPassword: payload.confirmPassword,
      }));
      props.nextStep();
    } else {
      setApiResponse({
        type: ENUMS.MODAL_TYPES.ERROR,
        title: "Error",
        body: "Email already exists.",
      });
      setShowModal(true);
    }
  };
  const performLogin = async () => {
    setBtnLoader(true);
    const getData = await getTempUserDetail();
    if (getData) {
      let payload = {
        email: getData?.item?.email,
        password: getData?.item?.password,
      };
      try {
        const result = await performLoginThroughFirebase(payload);
        if (result) {
          const {
            user: { accessToken, uid, reloadUserInfo },
          } = result;
          setToken(accessToken);
          setProfile({
            uid,
            reloadUserInfo,
          });
          const responseData = await getUserDetails(uid);
          setSecondaryProfile(responseData);
          navigate({ pathname: routePaths.home });
        }
        setBtnLoader(true);
        setShowModal(false);
      } catch (e) {
        setShowModal(true);
        setApiResponse({
          type: ENUMS.MODAL_TYPES.ERROR,
          title: "Error",
          body: e.message,
        });
        setBtnLoader(true);
        console.log("Error : ", e.message);
      }
    }
  };
  return (
    <div className="signin-container">
      {!isCompanyLogin && <Navigation />}
      <div className="leftPlan" id="leftPlan">
        <div className="leftPlanInner">
          <div className="mobile-logo-cont">
            {isCompanyLogin && <div className="wmcImagBox">
              <img src={wmcLogo} height="auto" width="100%" alt="no-image" style={{ borderRadius: 0 }} />
            </div>}
          </div>
          <div className="mobileImageBox">
            <MedicalCard {...userDetails} />
            {/* <img src={mobileLogo} height="auto" width="100%" /> */}
            {/* <div>
              <h1 className="msg">{getOurApp}</h1>
              <div className="store-logo-cont">
                <a href={constants.appStoreAppLink}>
                  <img src={appStoreLogo} height="auto" width="100%" />
                </a>
                <a href={constants.playStoreAppLink}>
                  <img src={playStoreLogo} height="auto" width="100%" />
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="right-container">
        <div className="wizard-wrap">
          <>
            <div className="wmc-heading-text">
              <h1 className="welcome">{registerNewWMCAcc}</h1>
              <p className="signInMsg">{pleaseFilloutPersonalInfo}</p>
            </div>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <StepWizard onStepChange={handleStepChange}>
              <Step1 />
              <Step2 />
              <Step3 />
              <Step4 />
              <Step5 />
              {!isCompanyLogin && <Step6 />}
            </StepWizard>
          </>
          <div className="already-have-an-account-cont">
            <Link to="/sign-in">
              <p className="noAccountLink">{alreadyHaveAndAcc}</p>
            </Link>
          </div>
        </div>
      </div>
      <GenericModal
        show={showModal}
        type={apiResponse.type}
        title={apiResponse.title}
        body={apiResponse.body}
        buttonText={constants.ok}
        btnLoader={btnLoader}
        handleClose={() => {
          if (apiResponse.type == ENUMS.MODAL_TYPES.ERROR) {
            setShowModal(false);
          } else {
            searchParams.delete("seesion_id");
            setSearchParams(searchParams);
            performLogin();
          }
        }}
      />
    </div>
  );
};

export default SignUp;
