import React from "react";
import { images } from "common/images";
import Grid from "@mui/material/Grid";
import { InputField } from "components/input-field";
import { colors } from "common/theme";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export const EmergencyInputField = ({
  fields,
  fieldCount,
  handleInputChange,
  handleSubmit,
  handleDeleteButtonClick,
  emergencyRef,
}) => {
  const { deleteIcon } = images;
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={0.7}>
        {Array.from({ length: fieldCount }, (_, index) => {
          const suffix = index + 1;
          return (
            <React.Fragment key={index}>
              <div
                style={{
                  alignSelf: "flex-start",
                  fontSize: 16,
                  color: "black",
                  fontWeight: "700",
                  paddingTop: 5,
                  paddingBottom: 5,
                  marginTop: 10,
                  marginLeft: 3,
                  marginRight: 3,
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <span>{`Emergency`}</span>
                <button
                  className="delete-btn"
                  onClick={handleDeleteButtonClick}
                >
                  <div
                    style={{
                      border: "1px solid",
                      width: 25,
                      borderRadius: 10,
                      backgroundColor: colors.darkRed,
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span>x</span>
                  </div>
                </button>
              </div>

              <Grid item xs={12} xl={12} lg={12} md={12}>
                <div className="mb-1">
                  <InputField
                    name={`name_${suffix}`}
                    id={`name_${suffix}`}
                    placeholder={`Name`}
                    value={fields[`name_${suffix}`]}
                    onChange={(e) =>
                      handleInputChange(`name_${suffix}`, e.target.value)
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12} xl={12} lg={12} md={12}>
                <div className="mb-1">
                  <InputField
                    name={`phone_${suffix}`}
                    id={`phone_${suffix}`}
                    placeholder={`Phone`}
                    value={fields[`phone_${suffix}`]}
                    onChange={(e) =>
                      handleInputChange(`phone_${suffix}`, e.target.value)
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12} xl={12} lg={12} md={12}>
                <div className="mb-1" ref={emergencyRef}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Relationship
                      </InputLabel>
                      <Select
                        sx={{
                          boxShadow: "none",
                          ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={fields[`relationship_${suffix}`]}
                        label="relation"
                        onChange={(e) =>
                          handleInputChange(
                            `relationship_${suffix}`,
                            e.target.value
                          )
                        }
                        placeholder={`Relation`}
                      >
                        <MenuItem value={"Father"}>Father</MenuItem>
                        <MenuItem value={"Mother"}>Mother</MenuItem>
                        <MenuItem value={"Sister"}>Sister</MenuItem>
                        <MenuItem value={"Brother"}>Brother</MenuItem>
                        <MenuItem value={"Brother"}>Son</MenuItem>
                        <MenuItem value={"Brother"}>Daughter</MenuItem>
                        <MenuItem value={"Brother"}>Girlfriend</MenuItem>
                        <MenuItem value={"Brother"}>Wife</MenuItem>
                        <MenuItem value={"Brother"}>Husband</MenuItem>
                        <MenuItem value={"Brother"}>Grand Father</MenuItem>
                        <MenuItem value={"Brother"}>Grand Mother</MenuItem>
                        <MenuItem value={"Brother"}>Other</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </form>
  );
};
